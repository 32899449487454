import { stateToGetters } from '@/apps/admin/store/helpers';
import {
    CHAT_BOX_OPEN,
    CHAT_BOX_CLOSE,
    CHAT_BOX_NOTIFY,
    CHAT_BOX_SET_CHATS,
    CHAT_BOX_SET_FILTER,
    CHAT_BOX_REMOVE_CHAT,
    CHAT_BOX_UPDATE_CHATS,
    CHAT_BOX_SET_MESSENGERS,
    CHAT_BOX_SET_COMMON_COUNTER,
    CHAT_BOX_SHOW_LIST,
    CHAT_BOX_SET_SOUND_NOTIFICATION_ACTIVE,
    CHAT_BOX_SET_NOTIFICATION_SOUND,
} from '@/apps/admin/store/mutation-types';
import ChatBoxService from '@/apps/admin/services/messengers/chat-box/ChatBoxService';

const state = {
    messengersList: [],
    commonUnread: 0,
    opened: false,
    notifyTrigger: false,
    chats: [],
    filter: ['0', '1'],
    viewListTrigger: false,
    soundNotificationsActive: false,
    selectedNotificationsSound: ChatBoxService.DEFAULT_SOUND,
};

const getters = {
    ...stateToGetters(state),
    filteredChats(state) {
        if (state.filter.length === 0) {
            return state.chats;
        }

        let filtered = state.chats.filter((item) => {
            if (state.filter.includes('0') && item.assign_id) {
                return true;
            }
            return !!(state.filter.includes('1') && !item.assign_id);
        });

        filtered.sort((a, b) => {
            if (a.chat_unread_messages > b.chat_unread_messages) {
                return -1;
            }
            if (a.chat_unread_messages < b.chat_unread_messages) {
                return 1;
            }

            moment.suppressDeprecationWarnings = true;
            let dateA = moment(a.message.created);
            let dateB = moment(b.message.created);
            moment.suppressDeprecationWarnings = false;

            if (dateA.isAfter(dateB)) {
                return -1;
            }
            if (dateA.isBefore(dateB)) {
                return 1;
            }

            return 0;
        });

        return filtered;
    },
};

const mutations = {
    [CHAT_BOX_REMOVE_CHAT](state, value) {
        state.chats = state.chats.filter((chat) => chat.id !== value);
    },
    [CHAT_BOX_UPDATE_CHATS](state, data) {
        data.forEach((newChat) => {
            let existingChat = state.chats.find((chat) => chat.id === newChat.id);
            if (existingChat) {
                Object.keys(newChat).forEach((key) => {
                    existingChat[key] = newChat[key];
                });
            } else {
                state.chats.push(newChat);
            }
        });
    },
    [CHAT_BOX_SET_COMMON_COUNTER](state, value) {
        state.commonUnread = value;
    },
    [CHAT_BOX_SET_FILTER](state, value) {
        state.filter = value;
    },
    [CHAT_BOX_SET_SOUND_NOTIFICATION_ACTIVE](state, value) {
        state.soundNotificationsActive = value;
    },
    [CHAT_BOX_SET_NOTIFICATION_SOUND](state, value) {
        state.selectedNotificationsSound = value;
    },
    [CHAT_BOX_SET_CHATS](state, chats) {
        state.chats = chats;
    },
    [CHAT_BOX_SET_MESSENGERS](state, list) {
        state.messengersList = list;
    },
    [CHAT_BOX_OPEN](state) {
        state.opened = true;
    },
    [CHAT_BOX_CLOSE](state) {
        state.opened = false;
    },
    [CHAT_BOX_SHOW_LIST](state) {
        state.viewListTrigger = true;
        setTimeout(() => {
            state.viewListTrigger = false;
        }, 1000);
    },
    [CHAT_BOX_NOTIFY](state) {
        state.notifyTrigger = true;
        setTimeout(() => {
            state.notifyTrigger = false;
        }, 1000);
    },
};

const actions = {
    initChatBoxUpdating({ dispatch, commit }) {
        // Initial force close after saved
        commit(CHAT_BOX_CLOSE);

        remove_socket_listener('chat_box_chats_updated');
        add_socket_listener('chat_box_chats_updated', (message) => {
            if (message.type === 'update') {
                dispatch('updateOrAddChats', message.data);
            } else if (message.type === 'remove') {
                dispatch('removeChat', message.data.id);
            }
        });

        remove_socket_listener('chat_box_update_unread_counter');
        add_socket_listener('chat_box_update_unread_counter', ({ data }) => {
            if (!data) {
                return;
            }
            const { counter } = data;
            dispatch('updateCommonCounter', counter ?? 0);
        });

        // Notifications
        let socket = io.connect(`//${window.location.host}/notification`);
        socket.on('notification', (data) => {
            if (data.command === 'message' && data.scope === 'messengers') {
                dispatch('notify');
            }
        });
    },
    updateCommonCounter({ commit }, value) {
        commit(CHAT_BOX_SET_COMMON_COUNTER, value);
    },
    updateOrAddChats({ commit }, chats) {
        commit(CHAT_BOX_UPDATE_CHATS, chats);
    },
    removeChat({ commit }, chatId) {
        commit(CHAT_BOX_REMOVE_CHAT, chatId);
    },
    setChatBoxFilter({ commit }, value) {
        commit(CHAT_BOX_SET_FILTER, value);
    },
    setChatBoxSoundNotificationsActive({ commit }, value) {
        commit(CHAT_BOX_SET_SOUND_NOTIFICATION_ACTIVE, value);
    },
    setChatBoxNotificationsSound({ commit }, value) {
        commit(CHAT_BOX_SET_NOTIFICATION_SOUND, value);
    },
    async fetchChats({ commit }) {
        const chats = await ChatBoxService.fetchChats();
        commit(CHAT_BOX_SET_MESSENGERS, chats.data.messengers ?? []);
        commit(CHAT_BOX_SET_NOTIFICATION_SOUND, chats.data.selected_notification_sound ?? ChatBoxService.DEFAULT_SOUND);
        commit(CHAT_BOX_SET_CHATS, chats.data.data ?? []);
        commit(CHAT_BOX_SET_COMMON_COUNTER, chats.data.counter ?? 0);
    },
    open({ commit }) {
        commit(CHAT_BOX_OPEN);
    },
    close({ commit }) {
        commit(CHAT_BOX_CLOSE);
    },
    showList({ commit }) {
        commit(CHAT_BOX_SHOW_LIST);
    },
    notify({ commit }) {
        commit(CHAT_BOX_NOTIFY);
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
