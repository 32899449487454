export default {
    data() {
        return {
            calendarColored: false,
        };
    },
    mounted() {
        this.calendarColored = JSON.parse(localStorage.getItem('scheduling-calendar-colored'));
    },
    methods: {
        toggleColorOnScheduler() {
            this.calendarColored = !this.calendarColored;
            localStorage.setItem('scheduling-calendar-colored', this.calendarColored);
        },
    },
};
